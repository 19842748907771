import { Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { RouteAuthData } from '../../types/vev-route';

@Injectable()
export class NavigationService {
  public constructor(private readonly authorizationService: AuthorizationService) {}

  public isRouteAllowed(auth: RouteAuthData | RouteAuthData[]): boolean {
    if (!auth) {
      return true;
    }

    const authArray = Array.isArray(auth) ? auth : [auth];
    return authArray.some((authElement) => this.authorizationService.canAccess(authElement.entity, authElement.action));
  }

  public isRoleAllowed(roles: string[] | undefined): boolean {
    if (!roles) {
      return true;
    }

    return roles.includes(this.authorizationService.getLoggedUser().role);
  }
}
